export function getIntro(opt) {
    return axios.post('/about/intro/get/', opt);
}

export function setIntro(opt) {
    return axios.post('/about/intro/insert/', opt);
}

export function getHonor(opt) {
    return axios.post('/about/honor/get/', opt);
}

export function addHonor(opt) {
    return axios.post('/about/honor/insert/', opt);
}

export function delHonor(opt) {
    return axios.post('/about/honor/delete/', opt);
}

export function getProcess(opt) {
    return axios.post('/about/develop_process/get/', opt);
}

export function addProcess(opt) {
    return axios.post('/about/develop_process/insert/', opt);
}

export function delProcess(opt) {
    return axios.post('/about/develop_process/delete/', opt);
}
