<template>
  <div>
    <el-card class="box-card" shadow="always">
      <el-table
          :data="processList"
          :fit="true"
          max-height="800"
          style="width: 100%">
        <el-table-column
            type="index"
            align="center"
            label="序号">
        </el-table-column>
        <el-table-column
            label="年份"
            prop="year"
            align="center">
        </el-table-column>
        <el-table-column
            align="center"
            prop="label"
            label="标题">
        </el-table-column>
        <el-table-column
            align="center"
            prop="descContent"
            label="描述">
        </el-table-column>
        <el-table-column
            prop="type"
            align="center"
            label="操作">
          <template slot="header" slot-scope="scope">
            <el-button size="medium" type="primary" @click="addProcess">新增</el-button>
          </template>
          <template slot-scope="scope">
            <el-link :underline="false" type="danger" @click="del(scope.row.id)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
        title="新增"
        :visible.sync="addDialogVisible"
        width="50%">
      <el-form ref="form" :model="addForm" label-width="120px">
        <el-form-item label="年份" >
          <el-input type="number" min="2000" v-model="addForm.year"></el-input>
        </el-form-item>
        <el-form-item label="标题">
          <el-input type="text" v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" v-model="addForm.desc" :autosize="{ minRows: 6, maxRows: 20}"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button @click="submit">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getProcess, delProcess, addProcess} from "@/plugins/api/company";

export default {
  name: 'Process',
  data() {
    return {
      processList: [],
      previewInfo: {},
      addDialogVisible: false,
      addForm: {
        year: '',
        title: '',
        desc: '',
      },
      fileList: []
    }
  },
  methods: {
    addProcess() {
      this.addDialogVisible = true
    },
    async getProcess() {
      let res = await getProcess();
      this.processList = res
      console.log(res)
    },
    async submit() {
      await addProcess({
        year: this.addForm.year,
        label: this.addForm.title,
        desc_content: this.addForm.desc,
      })
      this.getProcess();
      this.addDialogVisible = false;
      this.addForm = {
        year: '',
        title: '',
        desc: '',
      };
    },
    async del(id) {
      await delProcess({id})
      this.getProcess()
    }
  },
  created() {
    this.getProcess()
  }
}
</script>

<style scoped>
</style>
